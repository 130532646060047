import PropTypes from 'prop-types'
import React from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'

import PriceFormatter from '../../lib/formatters/PriceFormatter'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { subscribeRateChange, unsubscribeRateChange } from '../../socket'

Highcharts.setOptions({
  time: {
    timezoneOffset: -9 * 60,
  },
})

dayjs.extend(utc)
dayjs.extend(timezone)

export default class AreaChart extends React.Component {
  static propTypes = {
    pair: PropTypes.object,
    data: PropTypes.array.isRequired,
    periods: PropTypes.arrayOf(PropTypes.object),
    unit: PropTypes.number,
    range: PropTypes.string,
    loading: PropTypes.bool,
    onRefresh: PropTypes.func,
    refreshingRate: PropTypes.number,
  }

  constructor(props) {
    super(props)
    this.handleRateUpdate = this.handleRateUpdate.bind(this)
    this.lastData = props.data.length > 0 && props.data[props.data.length - 1]
    this.chartComponent = React.createRef()
  }

  generateDataMap(data) {
    this.dataMap = data.reduce((map, e) => {
      map[e[0]] = { open: e[1], high: e[2], low: e[3], close: e[4] }

      return map
    }, {})
  }

  componentDidMount() {
    this.generateDataMap(this.props.data)
    this.updatingCount = 0
    const exchangeCode = this.props.pair.chart_provider_exchange.code
    const productCode = this.props.pair.product_code

    subscribeRateChange(exchangeCode, productCode, this.handleRateUpdate)
  }

  handleRateUpdate({ time, bid }) {
    if (
      !this.lastData ||
      this.props.loading ||
      !this.chartComponent.current.chart
    ) {
      return
    }
    if (++this.updatingCount == this.props.refreshingRate) {
      this.props.onRefresh()
      this.updatingCount = 0
    }

    let [lastTime, open, high, low] = this.lastData

    const series = this.chartComponent.current.chart.series[0]

    let unitTime = time - (time % this.props.unit)

    switch (this.props.range) {
      case 'daily':
        unitTime = dayjs.utc(time).startOf('day').valueOf()
        break
      case 'weekly':
        unitTime = dayjs.utc(time).startOf('week').valueOf()
        break
      case 'monthly':
        unitTime = dayjs.utc(time).startOf('month').valueOf()
        break
    }

    if (lastTime == unitTime) {
      high = high < bid ? bid : high
      low = low > bid ? bid : low

      const newCandlestick = [unitTime, open, high, low, bid]
      this.lastData = newCandlestick

      this.dataMap[unitTime] = { open, high, low, close: bid }
      series.data[series.data.length - 1].update([unitTime, bid], true)
    } else {
      const newCandlestick = [unitTime, open, high, low, bid]
      this.lastData = newCandlestick
      this.dataMap[unitTime] = { open: bid, high: bid, low: bid, close: bid }
      series.addPoint([unitTime, bid], true, true)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.generateDataMap(this.props.data)
    }

    const chart = this.chartComponent.current.chart

    if (this.props.loading) {
      chart.showLoading()
    } else {
      chart.hideLoading()
      this.lastData =
        this.props.data.length > 0 &&
        this.props.data[this.props.data.length - 1]
    }
  }

  componentWillUnmount() {
    const exchangeCode = this.props.pair.chart_provider_exchange.code
    const productCode = this.props.pair.product_code

    unsubscribeRateChange(exchangeCode, productCode, this.handleRateUpdate)
  }

  shouldComponentUpdate(nextProps) {
    return !nextProps.loading
  }

  series() {
    const series = [
      {
        id: 'area',
        name: 'area',
        data: this.props.data.map((e) => [e[0], e[4]]),
        zIndex: 2,
        states: { hover: { lineWidth: 2 } },
      },
    ]

    return series
  }

  config() {
    const { range, pair } = this.props
    const dataMap = this.dataMap
    const formatOptions = { round: pair.precision, format: pair.convert_to }

    return {
      chart: {
        type: 'area',
        animation: false,
        style: {
          fontFamily:
            '-apple-system,BlinkMacSystemFont,Roboto,"Segoe UI",Helvetica,Arial,"Noto Sans JP","ヒラギノ角ゴ ProN","Hiragino Kaku Gothic ProN",YuGothic,"游ゴシック",Meiryo,"メイリオ","MS PGothic","ＭＳ Ｐゴシック",sans-serif',
        },
        height: '100',
        margin: 10,
      },
      title: { text: null },
      plotOptions: {
        series: {
          animation: false,
          dataGrouping: { enabled: false },
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2,
          },
          states: {
            hover: {
              halo: {
                size: 0,
              },
            },
          },
        },
        area: {
          threshold: false,
          lineColor: '#1a91d1',
          fillColor: 'rgba(111, 190, 247,0.3)',
        },
      },
      rangeSelector: { enabled: false },
      credits: { enabled: false },
      tooltip: {
        animation: false,
        hideDelay: false,
        formatter: function () {
          const point = dataMap[this.x]
          if (!point) {
            return false
          }

          let dateFormatter = '%m/%d %H:%M'
          if (range == 'daily' || range == 'weekly' || range == 'monthly') {
            dateFormatter = '%Y/%m/%d'
          }
          const formattedDate = Highcharts.dateFormat(dateFormatter, this.x)
          const formattedOpen = new PriceFormatter(
            point.open,
            formatOptions
          ).result()
          const formattedHigh = new PriceFormatter(
            point.high,
            formatOptions
          ).result()
          const formattedLow = new PriceFormatter(
            point.low,
            formatOptions
          ).result()
          const formattedClose = new PriceFormatter(
            point.close,
            formatOptions
          ).result()

          return `<b>${formattedDate}</b><br/>
                  <b>始値</b>：${formattedOpen}<br/>
                  <b>高値</b>：${formattedHigh}<br/>
                  <b>安値</b>：${formattedLow}<br/>
                  <b>終値</b>：${formattedClose}<br/>
                  `
        },
        distance: 24,
        backgroundColor: 'rgba(255, 255, 255, .9)',
        borderRadius: 3.75,
        borderWidth: 0,
        style: { color: '#263238', fontSize: '12' },
        shadow: {
          color: '#000',
          width: 3,
          offsetX: 0,
          offsetY: 1,
          opacity: 0.25,
        },
      },
      legend: { enabled: false },
      xAxis: {
        title: { text: null },
        tickAmount: 2,
        gridLineWidth: 0,
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%m/%d',
          week: '%Y/%m/%d',
          month: '%Y/%m',
        },
        labels: {
          style: { color: '#79808f', fontSize: '10px' },
          y: 10,
        },
        crosshair: {
          color: '#fed55d',
          dashStyle: 'Solid',
        },
      },
      yAxis: {
        title: { text: null },
        tickAmount: 4,
        gridLineColor: '#e5e5e5',
        floor: 0,
        labels: {
          align: 'right',
          x: 0,
          y: -3,
          width: 40,
          step: 1,
          style: { color: '#79808f', fontSize: '10px' },
          formatter: function () {
            return new PriceFormatter(this.value, formatOptions).result()
          },
        },
        opposite: true,
        showLastLabel: false,
        startOnTick: true,
        endOnTick: false,
        crosshair: {
          color: '#fed55d',
          dashStyle: 'Solid',
        },
      },
      series: this.series(),
    }
  }

  render() {
    return (
      <HighchartsReact
        ref={this.chartComponent}
        highcharts={Highcharts}
        options={this.config()}
      />
    )
  }
}
