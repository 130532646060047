import PropTypes from 'prop-types'
import React from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more.src'
import Indicators from 'highcharts/indicators/indicators'
import Boost from 'highcharts/modules/boost'

import PriceFormatter from '../../lib/formatters/PriceFormatter'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { subscribeRateChange, unsubscribeRateChange } from '../../socket'

Indicators(Highcharts)
HighchartsMore(Highcharts)
Boost(Highcharts)

Highcharts.setOptions({
  time: {
    timezoneOffset: -9 * 60,
  },
})

dayjs.extend(utc)
dayjs.extend(timezone)

export default class CandlestickChart extends React.Component {
  static propTypes = {
    pair: PropTypes.object,
    data: PropTypes.array.isRequired,
    periods: PropTypes.arrayOf(PropTypes.object),
    unit: PropTypes.number,
    range: PropTypes.string,
    loading: PropTypes.bool,
    defaultChartRange: PropTypes.number,
    onRefresh: PropTypes.func,
    refreshingRate: PropTypes.number,
    mode: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.handleRateUpdate = this.handleRateUpdate.bind(this)
    this.lastData = props.data.length > 0 && props.data[props.data.length - 1]
    this.chartComponent = React.createRef()
  }

  componentDidMount() {
    this.updatingCount = 0
    const exchangeCode = this.props.pair.chart_provider_exchange.code
    const productCode = this.props.pair.product_code

    subscribeRateChange(exchangeCode, productCode, this.handleRateUpdate)
  }

  handleRateUpdate({ time, bid }) {
    if (!this.lastData || this.props.loading) {
      return
    }
    if (++this.updatingCount == this.props.refreshingRate) {
      this.props.onRefresh()
      this.updatingCount = 0
    }

    let [lastTime, open, high, low] = this.lastData

    const series = this.chartComponent.current.chart.series[0]

    let unitTime = time - (time % this.props.unit)

    switch (this.props.range) {
      case 'daily':
        unitTime = dayjs.utc(time).startOf('day').valueOf()
        break
      case 'weekly':
        unitTime = dayjs.utc(time).startOf('week').valueOf()
        break
      case 'monthly':
        unitTime = dayjs.utc(time).startOf('month').valueOf()
        break
    }

    if (lastTime == unitTime) {
      high = high < bid ? bid : high
      low = low > bid ? bid : low

      const newCandlestick = [unitTime, open, high, low, bid]
      this.lastData = newCandlestick

      if (this.props.mode == 'pc') {
        series.data[series.data.length - 1].update(newCandlestick, true)
      }
    } else {
      const newCandlestick = [unitTime, bid, bid, bid, bid]
      this.lastData = newCandlestick

      if (this.props.mode == 'pc') {
        series.addPoint(newCandlestick, true, true)
      }
    }
  }

  componentDidUpdate(prevProps) {
    const chart = this.chartComponent.current.chart

    if (this.props.loading) {
      chart.showLoading()
    } else {
      chart.hideLoading()
      this.lastData =
        this.props.data.length > 0 &&
        this.props.data[this.props.data.length - 1]

      if (chart && chart.xAxis[0]) {
        const lastTimestamp = this.lastData[0]
        chart.xAxis[0].setExtremes(
          lastTimestamp - this.props.defaultChartRange * this.props.unit,
          lastTimestamp,
          true,
          false
        )
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    return !nextProps.loading
  }

  componentWillUnmount() {
    const exchangeCode = this.props.pair.chart_provider_exchange.code
    const productCode = this.props.pair.product_code

    unsubscribeRateChange(exchangeCode, productCode)
  }

  sma(linkedTo, period, color) {
    return {
      type: 'sma',
      name: `移動平均(${period})`,
      linkedTo,
      lineWidth: 1,
      zIndex: 0,
      color,
      params: { period },
    }
  }

  series() {
    const series = [
      {
        id: 'candlestick',
        name: 'candlestick',
        type: 'candlestick',
        data: this.props.data,
        zIndex: 2,
        states: { hover: { lineWidth: 2 } },
      },
    ]
    this.props.periods.forEach((p) =>
      series.push(this.sma('candlestick', p.period, p.color))
    )

    return series
  }

  config() {
    const { range, pair, mode } = this.props
    const formatOptions = { round: pair.precision, format: pair.convert_to }

    return {
      boost: {
        useGPUTranslations: false,
      },
      chart: {
        animation: false,
        style: {
          fontFamily:
            '-apple-system,BlinkMacSystemFont,Roboto,"Segoe UI",Helvetica,Arial,"Noto Sans JP","ヒラギノ角ゴ ProN","Hiragino Kaku Gothic ProN",YuGothic,"游ゴシック",Meiryo,"メイリオ","MS PGothic","ＭＳ Ｐゴシック",sans-serif',
        },
        marginRight: 65,
        marginTop: 30,
      },
      plotOptions: {
        series: {
          dataGrouping: { enabled: false },
          point: {
            events: {
              mouseOver: function (e) {
                const chart = this.series.chart
                if (!chart.lbl) {
                  const yPos = mode == 'pc' ? -2 : 30
                  chart.lbl = chart.renderer
                    .label('', chart.chartWidth - 20, yPos)
                    .css({
                      color: '#888',
                    })
                    .attr({ 'text-anchor': 'end', color: '#eee' })
                    .add()
                }
                if (e.target.high) {
                  let pointDetail = ''
                  const open = new PriceFormatter(
                    e.target.open,
                    formatOptions
                  ).result()
                  const high = new PriceFormatter(
                    e.target.high,
                    formatOptions
                  ).result()
                  const low = new PriceFormatter(
                    e.target.low,
                    formatOptions
                  ).result()
                  const close = new PriceFormatter(
                    e.target.close,
                    formatOptions
                  ).result()

                  if (mode == 'pc') {
                    pointDetail = `始値：${open} 高値：${high} 安値：${low} 終値：${close}`
                  } else {
                    pointDetail = `始値：${open} 高値：${high}<br/>安値：${low} 終値：${close}`
                  }
                  chart.lbl.show().attr({
                    text: pointDetail,
                  })
                }
              },
              mouseOut: function () {
                const chart = this.series.chart
                if (chart.lbl) {
                  chart.lbl.hide()
                }
              },
            },
          },
        },
        candlestick: {
          color: '#1e91d1',
          lineColor: '#1e91d1',
          upColor: '#d83835',
          upLineColor: '#d83835',
          lineWidth: 1,
          showInLegend: false,
        },
        sma: {
          allowPointSelect: false,
          enableMouseTracking: false,
          marker: {
            enabled: false,
            symbol: 'circle',
            radius: 2,
          },
          states: {
            hover: {
              halo: {
                size: 0,
              },
            },
          },
          showInLegend: true,
        },
      },
      legend: {
        enabled: true,
        itemStyle: {
          fontFamily:
            '-apple-system,BlinkMacSystemFont,Roboto,"Segoe UI",Helvetica,Arial,"Noto Sans JP","ヒラギノ角ゴ ProN","Hiragino Kaku Gothic ProN",YuGothic,"游ゴシック",Meiryo,"メイリオ","MS PGothic","ＭＳ Ｐゴシック",sans-serif',
          fontSize: '10px',
          color: '#8b8b8b',
        },
        align: 'left',
        verticalAlign: 'top',
        y: -20,
        padding: 0,
        margin: 0,
      },
      rangeSelector: { enabled: false },
      scrollbar: { height: 0 },
      credits: { enabled: false },
      tooltip: false,
      navigator: {
        maskFill: 'rgba(255,255,255,0.3)',
        series: {
          lineColor: '#9cadc0',
          fillColor: '#9cadc0',
        },
        margin: 0,
        xAxis: {
          labels: {
            style: {
              color: '#6D869F',
              fontWeight: 'bold',
            },
          },
          dateTimeLabelFormats: {
            millisecond: ' ',
            second: ' ',
            minute: ' ',
            hour: ' ',
            day: '%Y/%m/%d',
            week: '%Y/%m/%d',
            month: '%Y/%m',
            year: '%Y',
          },
        },
      },
      xAxis: {
        range: this.props.defaultChartRange * this.props.unit,
        gridLineWidth: 0,
        tickPixelInterval: 150,
        dateTimeLabelFormats: {
          day: '%m/%d',
          week: '%Y/%m/%d',
          month: '%Y/%m',
        },
        labels: {
          style: { color: '#79808f', fontSize: '10px' },
        },
        crosshair: {
          color: '#fed55d',
          dashStyle: 'Solid',
          label: {
            enabled: true,
            shape: 'square',
            backgroundColor: '#fec317',
            style: { color: '#000' },
            padding: 4,
            formatter: function (value) {
              let dateFormatter = '%m/%d %H:%M'
              if (range == 'daily' || range == 'weekly' || range == 'monthly') {
                dateFormatter = '%Y/%m/%d'
              }

              return Highcharts.dateFormat(dateFormatter, value)
            },
          },
        },
      },
      yAxis: [
        {
          gridLineColor: '#e5e5e5',
          floor: 0,
          startOnTick: false,
          endOnTick: false,
          labels: {
            align: 'left',
            x: 8,
            y: 3,
            step: 1,
            style: { color: '#79808f', fontSize: '10px' },
            formatter: function () {
              return new PriceFormatter(this.value, formatOptions).result()
            },
          },
          showLastLabel: true,
          crosshair: {
            color: '#fec317',
            dashStyle: 'Solid',
            label: {
              enabled: true,
              shape: 'square',
              padding: 6,
              backgroundColor: '#fec317',
              formatter: function (value) {
                return new PriceFormatter(value, formatOptions).result()
              },
              style: { color: '#000' },
            },
          },
        },
        {
          linkedTo: 0,
          visible: true,
          title: false,
          labels: {
            useHTML: true,
            y: 3,
            formatter: function () {
              return `<span class="hc-label">${new PriceFormatter(
                this.value,
                formatOptions
              ).result()}</span>`
            },
          },
          events: {
            afterSetExtremes: function () {
              this.chart.yAxis[1].update({
                tickPositioner: function () {
                  const data = this.chart.series[0].data
                  if (data && data.length > 0) {
                    return [data[data.length - 1].close]
                  }
                },
              })
            },
          },
        },
      ],
      series: this.series(),
    }
  }

  render() {
    return (
      <HighchartsReact
        ref={this.chartComponent}
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={this.config()}
      />
    )
  }
}
